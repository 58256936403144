@import '../../scss/variables';
@import '../../react/NewsItem/index.module.scss';

[data-configuration='oneItem'],
[data-configuration='twoItems'],
[data-configuration='threeItems'], 
[data-configuration='fourItemsEqual'] {
    .newsListItem {
        @extend %decorativeNewsItem;
    }
}

[data-configuration='oneItem'] {
    .newsList {
        grid-template-columns: 1fr 4fr 1fr;

        // IE GRID FIXES:
        -ms-grid-columns: 1fr 4fr 1fr;
    }

    .newsListItem {
        grid-column: 2;

        // IE GRID FIXES:
        -ms-grid-column: 2;
    }
}

[data-configuration='twoItems'] {
    .newsList {
        grid-template-columns: repeat(2, 1fr);

        // IE GRID FIXES:
        -ms-grid-columns: 1fr 16px 1fr;
    }

    .newsListItem {
        // IE GRID FIXES:
        &:nth-child(2) {
            -ms-grid-column: 3;
        }
    }
}

[data-configuration='threeItems'],
[data-configuration='fourItemsDynamic'] {
    .newsList {
        grid-template-columns: 2fr repeat(2, 1fr);

        // IE GRID FIXES:
        -ms-grid-columns: 2fr 16px 1fr 16px 1fr;
    }

    .newsListItem {
        // IE GRID FIXES:
        &:nth-child(2) {
            -ms-grid-column: 3;
        }

        &:nth-child(3) {
            -ms-grid-column: 5;
        }
    }
}

[data-configuration='fourItemsDynamic'] {
    .newsListItem {
        &:nth-child(1),
        &:nth-child(2) {
            @extend %decorativeNewsItem;
            grid-row: 1/3;
        }
        &:nth-child(3),
        &:nth-child(4) {
            @extend %simpleNewsItem;
        }

        // IE grid fixes:
        &:nth-child(1),
        &:nth-child(2) {
            -ms-grid-row-span: 2;
        }
        &:nth-child(4) {
            -ms-grid-column: 5;
        }
        &:nth-child(4) {
            -ms-grid-row: 2;
        }
    }
}

[data-configuration='fourItemsDynamic'],
[data-configuration='fiveItems'] {
    .newsList {
        grid-template-rows: 1fr 1fr;
    }
}

[data-configuration='fourItemsEqual'],
[data-configuration='fiveItems'] {
    .newsList {
        grid-template-columns: repeat(4, 1fr);

        // IE GRID FIXES:
        -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr;
    }

    .newsListItem {
        // IE GRID FIXES:
        &:nth-child(2) {
            -ms-grid-column: 3;
        }
        &:nth-child(3) {
            -ms-grid-column: 5;
        }

        &:nth-child(4),
        &:nth-child(5) {
            -ms-grid-column: 7;
        }
    }
}

[data-configuration='fiveItems'] {
    .newsListItem {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            @extend %decorativeNewsItem;
            grid-row: 1/3;

            // IE GRID FIXES:
            -ms-grid-row-span: 2;
        }
        &:nth-child(4),
        &:nth-child(5) {
            @extend %simpleNewsItem;
        }

        &:nth-child(4) {
            // IE GRID FIXES:
            -ms-grid-row: 1;
        }

        &:nth-child(5) {
            // IE GRID FIXES:
            -ms-grid-row: 2;
        }
    }
}

.container {
    background: $lightergrey;
    margin: 48px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.seeAllNews{
    margin-top: 2em;
    border-color: transparent;
}

.newsList {
    display: grid;
    grid-column-gap: 16px;

    // IE GRID FIXES:
    display: -ms-grid;
}

.newsListItem {
    // Reset link styles:
    margin-top: 0;
    &::before {
        content: none;
    }

    // Styles added for external link indicators:
    a {
        border: none;

        &:hover {
            [class*='linkText'] {
                border-bottom-style: solid;
            }
        }

        [class*='linkText'] {
            border-bottom: 1px dotted;
        }

        &[href*="//"] {
            &::after {
                color: $white;
                content: '\f08e';
                font-family: $fontAwesome;
                margin-left: 8px;
            }
        }
    }
}

@media (max-width: $mobile-larger) {    
    .newsList {
        display: block;
    }

    %decorativeNewsItem {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }
}