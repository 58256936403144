@import '../../scss/variables';

.container[class*='decorativeNewsItem'] {
    display: grid;
    max-width: 800px;
}

%decorativeNewsItem [class*='container'],
.container[class*='decorativeNewsItem'] {
    height: 100%;
    position: relative;
    width: 100%;
    
    &[class*='hasImage'],
    &.hasImage {
        background-color: $darklake;
    }

    &[class*='noImage'],
    &.noImage {
        align-items: flex-end;
        background-color: $grey;
        display: flex;

        &::before {
            background-image: url('../../imgs/phoenix.svg');
            background-repeat: no-repeat;
            background-size: 150%;
            content: '';
            height: 100%;
            opacity: .16;
            position: absolute;
            top: 0; // IE 11 fix
            width: 100%;
        }

        [class*='textContainer'],
        .textContainer {
            width: calc(100% - 48px);
        }
    }
    
    [class*='tag'],
    .tag {
        background-color: $white;
        left: 24px;
        padding: 9px 8px 7px;
        position: absolute;
        top: 24px;

        + [class*="textContainer"],
        + .textContainer {
            margin-top: 64px;       
        }
    }

    [class*="textContainer"] *,
    .textContainer *,
    [class*="bookmark"],
    .bookmark {
        color: $white;
    }

    [class*="textContainer"],
    .textContainer {
        margin: 8px 24px 16px;
    }
}

%simpleNewsItem {
    background-color: $white;

    &:not(:last-child) {
        // Top news item
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 24px 24px 40px;
    }

    &:last-child {
        // Bottom news item
        flex-direction: column;
        padding: 0 24px 24px;

        &::before {
            border-bottom: 1px solid $mediumergrey;
            content: "";
            display: block;
            margin-bottom: 24px;
            width: 100%;
        }
    }

    [class*='tag'],
    .tag {
        display: inline-block;
        margin-bottom: 16px;
    }

    [class*='image'],
    .image {
        display: none;
    }

    [class*='titleLink'],
    .titleLink {
        [class*='linkText'] {
            color: $grey;
        }
    }
}

// Shared between %decorativeNewsItem and %simpleNewsItem:
.tag {
    // Reset li style:
    line-height: 1;
}

.image {
    height: auto;
    width: 100%;
}

.textContainer {
    position: relative;
}

.date {
    display: block;
    line-height: 24px;
}

.bookmark {
    position: absolute;
    right: 0;
    top: 0;
}

.titleLink {
    cursor: pointer;
    font-size: 16px;
    font-family: $sans-serif-medium;
    font-weight: $sans-medium;
    line-height: 22px;
}

.source {
    display: block;
    line-height: 13px;
    margin-top: 8px;
}